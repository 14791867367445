import { h } from 'vue'

import { NPerformantEllipsis } from 'naive-ui'

import { type NuxtLinkProps } from '#app'
import { NuxtLink } from '#components'

import { VtIcon, type IconName } from '@visiontree/vue-ui'

export const renderIcon = (name: IconName) => {
  return () => h(VtIcon, { name })
}

export const renderLink = (
  label: string,
  props: NuxtLinkProps,
  options?: { disableEllipsis?: boolean },
) => {
  return () => {
    if (options?.disableEllipsis) {
      return h(NuxtLink, props, { default: () => label })
    }

    return h(NPerformantEllipsis, null, {
      default: () => h(NuxtLink, props, { default: () => label }),
    })
  }
}
